import { Button, Container, Divider, Grid } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Fonctionnalites = () => {

    document.title = 'Comptin - Un logiciel unique et modulable en fonction des besoins de votre entreprise'

    return (
        <div>
            <div style={{ backgroundColor: '#252C4C', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h1>Fonctionnalités</h1>
                <div className="sub_h1">
                    Un logiciel unique et modulable en fonction des besoins de votre entreprise
                </div>
                </Container>
            </div>

            <FonctionBlock 
                bgColor="#F6F6F6"
                iconColor="#F57F17"
                titleIcon="analytics"
                title="Gestion et suivi de la comptabilité d'entreprise : achats, ventes, trésorerie"
                subtitle="Comptin simplifie et fluidifie les échanges entre les entreprises et leurs cabinets comptables"
                imgSrc="images/bg/accounting.png"
                gridDirection="row-reverse"
            >
                <p className="text-justify">
                    Enregistrez vos achats et associez vos pièces comptables aux factures. Suivez le règlement de vos fournisseurs en temps réel et obtenez des statistiques avancées sur vos dépenses.
                </p>
                <p className="text-justify">
                    Stockez vos factures clients, suivez vos encaissements clients et augmentez la satisfaction de vos clients.
                </p>
                <p className="text-justify">
                    Accédez à l'historique de vos paiements (règlements fournisseurs, encaissements clients, règlement de charges sociales, cotisations retraites...) et gérez vos paiements groupés (prélèvement, virement SEPA).
                </p>
                <p className="text-justify">
                    Téléchargez vos relevés bancaires sur Comptin et vérifiez que toutes les opérations sont bien présentes.
                </p>
                <p className="text-justify">
                    Votre cabinet pourra ainsi retrouver l'ensemble de vos pièces comptables (factures, avoirs, relevés bancaires) sur Comptin. Il pourra exporter l'ensemble de ces pièces et leurs fichiers associés.
                </p>
            </FonctionBlock>

            <FonctionBlock 
                bgColor="#FFF"
                iconColor="#43A047"
                titleIcon="piggy-bank"
                title="Synchronisation bancaire et rapprochements avec les paiements"
                subtitle="Connectez vos comptes bancaires sur Comptin et gardez un oeil sur votre trésorerie au jour le jour."
                imgSrc="images/bg/bank.png"
            >
                <p className="text-justify">
                    Pour récupérer vos transactions bancaires, Comptin fait appel aux services de prestataires tiers de confiance autorisés
                    à opérer par la Banque de France et disposant d'un agrément d'établissement de
                    Paiement auprès du régulateur bancaire Français (ACPR) sur les services d'accès aux
                    comptes et d'initiation de paiement de la DSP2.
                </p>
                <p className="text-justify">Ainsi vos comptes se synchronisent de manière sécurisée avec votre banque et vos
                    transactions sont cryptées puis transmises à Comptin afin de récupérer
                    automatiquement vos écritures bancaires.
                </p>
                <p className="text-justify">Comptin est capable d'apprendre la façon dont vous annoter les transactions et en quelques semaines 90% de vos transactions seront automatiquement affectées aux paiements correspondants.
                </p>
                <p className="text-justify">Vous avez la possibilité de connecter vos comptes avec le service <i>"JeDeclare.com"</i> pour les récupérer sur Comptin. Vous pouvez aussi connecter plus de comptes similaires à des comptes bancaires tels que <i>"Paypal"</i> ou <i>"Stripe"</i> par exemple.
                </p>
            </FonctionBlock>

            <FonctionBlock 
                bgColor="#F6F6F6"
                iconColor="#BE185D"
                titleIcon="print"
                title="Scanner intelligent : reconnaissance des pièces comptables"
                subtitle="Transmettez vos factures à Comptin et le scanner intelligent analyse automatiquement les pièces transmises"
                imgSrc="images/bg/ocr.png"
                gridDirection="row-reverse"
            >
                <p className="text-justify">
                    Fini la saisie comptable à l'ancienne, vous transmettez vos factures fournisseurs ou clients dans le format que vous souhaitez (pdf, jpg, excel, word, numbers, pages...) 
                    et le scanner intelligent extrait automatiquement les données utiles (n° de la pièce, date de la pièce, date d'échéance, fournisseur ou client, montants TTC TVA et HT).
                </p>
                <p className="text-justify">
                    Une fois les données extraites, Comptin affecte automatiquement le bon fournisseur ou client et effectue la ventilation des charges ou produits. Vous n'avez plus qu'à contrôler les données et confirmer.
                </p>
                <p className="text-justify">
                    Si le fournisseur ou le client est inconnu dans votre dossier, Comptin vous laisse le soin de créer le nouveau fournisseur ou client et d'indiquer pour cette première facture comment la comptabiliser. Ainsi les prochaines seront automatiquement traitées par la suite.
                </p>
            </FonctionBlock>

            <FonctionBlock 
                bgColor="#FFF"
                iconColor="#5B21B6"
                titleIcon="receipt"
                title="Gestion des notes de frais pour vous et vos collaborateurs"
                subtitle="Prenez en photo ou transmettez vos frais et Comptin s'occupe du reste !"
                imgSrc="images/bg/expense.png"
            >
                <p className="text-justify">
                    Vous invitez un client au restaurant ? Prenez en photo votre ticket avec Comptin et celui-ci sera automatiquement enregistré dans votre note de frais.
                </p>
                <p className="text-justify">
                    À la fin du mois ou du trimestre vous pouvez clôturer votre note de frais pour la transmettre à la comptabilité. Le résumé et le détail des frais peuvent être exportés.
                </p>
                <p className="text-justify">
                    Vous avez des collaborateurs ? Ils peuvent gérer leurs notes de frais directement depuis Comptin. Vous n'avez plus qu'à les valider pour les passer en comptabilité.
                </p>
            </FonctionBlock>

            <FonctionBlock 
                bgColor="#F6F6F6"
                iconColor="#1C4ED8"
                titleIcon="folder-open"
                title="Archivage et gestion électronique des documents centralisés"
                subtitle="Toutes vos pièces comptables sont archivées et accessibles par votre comptable"
                imgSrc="images/bg/ocr.png"
                gridDirection="row-reverse"
            >
                <p className="text-justify">
                    Dès lors que vous transmettez une facture ou un avoir à Comptin, celui-ci est archivé avec l'opération comptable. Vous pouvez ainsi la retrouver en quelques secondes avec les données associées.
                </p>
                <p className="text-justify">
                    Si vous déposez vos relevés de compte dans la comptabilité pour effectuer la vérification des transactions enregistrées, le relevé est archivé et est directement accessible par votre comptable.
                </p>

            </FonctionBlock>

            <FonctionBlock 
                bgColor="#FFF"
                iconColor="#1F2937"
                titleIcon="users"
                title="Gestion des utilisateurs entreprises et des accès cabinets"
                subtitle="Gérez les droits d'accès pour votre comptable ainsi que pour vos collaborateurs"
                imgSrc="images/bg/user.png"
            >
                <p className="text-justify">
                    Tous les dossiers permettent d'attribuer un accès entreprise principal et un accès pour votre comptable.
                </p>
                <p className="text-justify">
                    Avec l'accès comptable, celui-ci pourra contrôler votre comptabilité et récupérer vos opération, documents et relevés comptables.
                </p>
                <p className="text-justify">
                    Vous avez des collaborateurs, vous pouvez leur donner un accès à Comptin avec les limitations que vous souhaitez. Par exemple un collaborateur peut accèder uniquement à ses notes de frais mais n'aurait pas accès à la comptabilité.
                </p>
            </FonctionBlock>
            <div style={{ backgroundColor: '#F6F6F6', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                    <div className="text-center">
                        <h2 className="dark text-center">Vous souhaitez une démonstration de Comptin ?</h2>
                        <div className="sub_h2 dark" style={{ textAlign:'center'}}>
                            Découvrez nos tarifs adaptés à la taille de votre entreprise
                        </div>
                        <div className="text-center">
                            <Link to={`/pricing`}>
                                <Button variant="contained" color="secondary" className="call2action">Voir les tarifs <FontAwesomeIcon icon={['fa', 'chevron-right']} size="1x" fixedWidth /></Button>
                            </Link>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};


const FonctionBlock = props => {

    const { bgColor, titleIcon, iconColor, title, subtitle, gridDirection, children, imgSrc } = props;

    return (
        <div style={{ backgroundColor: bgColor, paddingTop: '50px', paddingBottom: '50px'}}>
                <Container className="fonction-block" maxWidth="xl">
                    <Grid container spacing={3} direction={gridDirection}>
                    
                        <Grid item md={6} sm={12} xs={12} className="flex items-center">
                        <div className="w-full flex-1 justify-center">
                            <Grid container spacing={3}>
                            <Grid item sm={2} xs={12} className="flex items-center">
                            <div className="w-full flex-1 justify-center text-center">
                    <FontAwesomeIcon icon={['fal', titleIcon]} size="3x" fixedWidth style={{ color: iconColor}} />
                    </div>
                        </Grid>
                            
                            <Grid item sm={10} xs={12} className="flex items-center">
                            <div className="w-full flex-1 justify-center">
                            <h2 className="dark">{title}</h2>
                        
                            </div>
                            </Grid>
                        </Grid>
                        <div className="sub_h2 dark" style={{ marginTop:'15px'}}>{subtitle}</div>
                            {children}
                        </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12} className="flex items-center">
                        <div className="w-full flex-1 justify-center">
                            <img src={imgSrc} style={{ margin:'auto', width:'100%'}} />
                        </div>
                            </Grid>
                        </Grid>
                </Container>
           </div>
    )
}

export default Fonctionnalites;