import { Container, FormControl, Grid, InputLabel, Paper, Select, MenuItem, TextField, Button, Alert, FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'is-empty';
import EmailValidation from 'emailvalid';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const ev = new EmailValidation({
	allowFreemail: true
});

const Contact = () => {

    document.title = 'Comptin - Demandez une démonstration ou posez une question'

    const services = [
        {
            tag: "demo",
            name: "Demande de démonstration",
            bcss: [{ email:"mig@gps-expert.net"}]
        },
        {
            tag: "commercial",
            name: "Question commerciale",
            bcss: [{ email:"mig@gps-expert.net"}]
        },
        {
            tag: "communication",
            name: "Question communication",
        },
        {
            tag: "technique",
            name: "Question technique ou besoin d'assistance",
            bcss: [{ email:"mig@gps-expert.net"}]
        },
        {
            tag: "autre",
            name: "Autre demande",
        }
    ]

    const contactTypes = [
        {
            tag: "cabinet",
            name: "Un cabinet comptable",
        },
        {
            tag: "entreprise",
            name: "Une entreprise",
        },
        {
            tag: "partenaire",
            name: "Un partenaire",
        },
        {
            tag: "particulier",
            name: "Un particulier",
        },
        {
            tag: "investisseur",
            name: "Un investisseur",
        }
    ]

    const initialData = {
         service: '',
         contact_type: '',
         raison_sociale: '',
         postal_code: '',
         message: '',
         prenom: '',
         nom: '',
         tel: '',
         email: ''
    }
    const location = useLocation();
    useEffect(() => {
        let params = location.search.replace(/^\?/,'').split('&');
        let p = []
        params.forEach(param => {
            let paramSplit = param.split('=');
            let tag = paramSplit[0]
            let value = paramSplit[1]
            p.push({tag, value});
        })

        let serviceParam = p.find(x => x.tag === 'service');
        if (!isEmpty(serviceParam)) {
            setData(prevData => ({...prevData, service: serviceParam.value }));
        }

        let versionParam = p.find(x => x.tag === 'version');
        if (!isEmpty(versionParam)) {
            setData(prevData => ({...prevData, message: `Bonjour,\n\nJe souhaiterais obtenir une démonstration pour l'offre "${versionParam.value}"\n\n` }));
        }

        let contactTypeParam = p.find(x => x.tag === 'contact_type');
        if (!isEmpty(contactTypeParam)) {
            setData(prevData => ({...prevData, contact_type: contactTypeParam.value }));
        }

        
    },[]);

    const [data, setData] = useState(initialData)

    const [error, setError] = useState({
        service: false,
        contact_type: false,
        raison_sociale: false,
        postal_code: false,
        message: false,
        prenom: false,
        nom: false,
        tel: false,
        email: false
    })

    const [isSend, setIsSend] = useState(false);
    const [internalError, setInternalError] = useState(false);
    const sendMessage = () => {
        const errorTmp = {
            service: false,
            contact_type: false,
            raison_sociale: false,
            postal_code: false,
            message: false,
            prenom: false,
            nom: false,
            tel: false,
            email: false
        };
        if (isEmpty(data.service)) {
            errorTmp.service = true;
        } else {
            errorTmp.service = false;
        }
        if (isEmpty(data.contact_type)) {
            errorTmp.contact_type = true;
        } else {
            errorTmp.contact_type = false;
        }
        if (!['particulier'].includes(data.contact_type) && (isEmpty(data.raison_sociale) || data.raison_sociale.length < 3)) {
            errorTmp.raison_sociale = true;
        } else {
            errorTmp.raison_sociale = false;
        }
        if (!['particulier'].includes(data.contact_type) && (isEmpty(data.postal_code) || data.postal_code.length < 5)) {
            errorTmp.postal_code = true;
        } else {
            errorTmp.postal_code = false;
        }
        if (isEmpty(data.message) || data.message.length < 20) {
            errorTmp.message = true;
        } else {
            errorTmp.message = false;
        }
        if (isEmpty(data.prenom) || data.prenom.length < 3) {
            errorTmp.prenom = true;
        } else {
            errorTmp.prenom = false;
        }
        if (isEmpty(data.nom) || data.nom.length < 3) {
            errorTmp.nom = true;
        } else {
            errorTmp.nom = false;
        }
        if (isEmpty(data.tel) || data.tel.length < 10) {
            errorTmp.tel = true;
        } else {
            errorTmp.false = true;
        }
        if (isEmpty(data.email)) {
            errorTmp.email = true;
        } else if (!isEmpty(data.email)) {
            const emailValidationRes = ev.check(data.email);
            if (!emailValidationRes.valid) {
                errorTmp.email = true;
            } else {
                errorTmp.email = false;
            }
        }

        setError(errorTmp);
        let errorInFields = Object.values(errorTmp).some(x => x === true)
        console.log(errorInFields)
        if (errorInFields === false) {
            console.log('SEND MAIL')

            let service = services.find(x => x.tag === data.service);
            let contactType = contactTypes.find(x => x.tag === data.contact_type);

            let formValues = {
                to: { email:"contact@comptin.fr"},
                ccs: null,
                reply_to:{
                    email: data.email,
                    name: data.prenom+' '+data.nom
                },
                bccs: service && service.bcss ? service.bcss : [],
                replace_params:{
                    service: service && service.name ? service.name : '',
                    contact_type: contactType && contactType.name ? contactType.name : '',
                    raison_sociale: data.raison_sociale ?? '',
                    postal_code: data.postal_code ?? '',
                    prenom: data.prenom,
                    nom: data.nom,
                    tel: data.tel,
                    email: data.email
                },
                options:[]
            }

            return axios.post('https://api.comptin.fr/emailing/public/sendmail/www_contact_form', formValues, {
                auth: {
                  username: '2f06f3815a22862acfa54277ff6c542810114973',
                  password: 'b65868dc62507aa2adb2eb0ee6b51dbfc3040b49'
                }
              }).then(() => {
                setInternalError(false)
                setIsSend(true);
                setData(initialData);
              }).catch(e => {
                setInternalError(true)
              })

            
        }
    };

    return (
        <div>
            {/* <div style={{ backgroundColor: '#FFF', paddingTop: '50px', paddingBottom: '50px'}}>
            <Container>
                <h1>Une tarification simple et adaptée</h1>
                <div className="sub_h1 dark">
                    Pour la gestion d'entreprise des indépendants, TPE ou PME
                </div>
                
                </Container>
            </div> */}
            <div style={{ backgroundColor: '#252C4C', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h1>Contactez-nous</h1>
                <div className="sub_h1">
                    Des questions ou une demande de tarif pour utiliser Comptin ? Envoyez votre demande
                </div>
                </Container>
            </div>
            <div style={{ backgroundColor: '#FFF', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h2 className="dark">Envoyer une demande à Comptin</h2>
                
                <div className="sub_h2 dark">
                    Complétez le formulaire ci-dessous
                </div>
                
                <Grid container spacing={3}>
                    <Grid item md={8} sm={12} xs={12}>
                        {internalError ? (
                            <Alert severity="error" className="mb-4">Une erreur interne est survenue, nous vous invitons à nous contacter par téléphone.</Alert>
                        ) : (
                            <>
                        {isSend ? (
                            <Alert severity="success">Votre demande à bien été transmise à nos services, vous serez recontacté rapidement.</Alert>
                            ) : (
                                <>
                        <Grid container spacing={3}>
                            <Grid item sm={12} xs={12}>
                                <FormControl fullWidth error={error.service}>
                                <InputLabel id="demo-simple-select-label">Sélectionnez le service concerné</InputLabel>
                                <Select
                                    value={data.service}
                                    label="Age"
                                    onChange={event => setData(prevData => ({...prevData, service: event.target.value}))}
                                >
                                    {services.map(service => {
                                        return (
                                            <MenuItem key={service.tag} value={service.tag}>{service.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                {error.service ? (
                                <FormHelperText>Veuillez sélectionner un service</FormHelperText>
                                ) : ''}
                                </FormControl>
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <FormControl fullWidth error={error.contact_type}>
                                <InputLabel id="demo-simple-select-label">Vous êtes ?</InputLabel>
                                <Select
                                    value={data.contact_type}
                                    label="Age"
                                    onChange={event => setData(prevData => ({...prevData, contact_type: event.target.value}))}
                                >
                                    {contactTypes.map(contactType => {
                                        return (
                                            <MenuItem key={contactType.tag} value={contactType.tag}>{contactType.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                {error.contact_type ? (
                                <FormHelperText>Veuillez faire un choix</FormHelperText>
                                ) : ''}
                                </FormControl>
                            </Grid>
                            <Grid item sm={5} xs={12}>
                                <TextField
                                    required={data.contact_type === 'particulier' ? false : true}
                                    disabled={data.contact_type === 'particulier' ? true : false}
                                    fullWidth
                                    error={error.raison_sociale}
                                    helperText={error.raison_sociale ? 'Veuillez saisir votre raison sociale' : ''}
                                    label="Raison sociale de votre entreprise"
                                    value={data.raison_sociale}
                                    onChange={event => setData(prevData => ({...prevData, raison_sociale: event.target.value}))}
                                    />
                            </Grid>
                            <Grid item sm={3} xs={12}>
                                <TextField
                                    required={data.contact_type === 'particulier' ? false : true}
                                    fullWidth
                                    error={error.postal_code}
                                    helperText={error.postal_code ? 'Obligatoire' : ''}
                                    label="Code postal"
                                    value={data.postal_code}
                                    onChange={event => setData(prevData => ({...prevData, postal_code: event.target.value}))}
                                    />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    multiline
                                    rows={8}
                                    error={error.message}
                                    helperText={error.message ? 'Le message est trop court' : ''}
                                    label="Saisir votre message"
                                    value={data.message}
                                    onChange={event => setData(prevData => ({...prevData, message: event.target.value}))}
                                    />
                            </Grid>
                        </Grid>
                        <p style={{ fontWeight:'bold', margin:'30px 0px 10px 0px', textAlign: 'left'}}>Vos coordonnées</p>
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    error={error.prenom}
                                    helperText={error.prenom ? 'Le prénom est trop court' : ''}
                                    label="Prénom"
                                    value={data.prenom}
                                    onChange={event => setData(prevData => ({...prevData, prenom: event.target.value}))}
                                    />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    error={error.nom}
                                    helperText={error.nom ? 'Le nom est trop court' : ''}
                                    label="Nom"
                                    value={data.nom}
                                    onChange={event => setData(prevData => ({...prevData, nom: event.target.value}))}
                                    />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    error={error.tel}
                                    helperText={error.tel ? 'Le numéro de téléphone n\'est pas valide' : ''}
                                    label="N° téléphone"
                                    value={data.tel}
                                    onChange={event => setData(prevData => ({...prevData, tel: event.target.value}))}
                                    />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    error={error.email}
                                    name="email"
                                    helperText={error.email ? 'L\'adresse e-mail n\'est pas valide' : ''}
                                    label="Adresse e-mail"
                                    value={data.email}
                                    onChange={event => setData(prevData => ({...prevData, email: event.target.value}))}
                                    />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                
                                <div className="text-center"><Button onClick={() => sendMessage()} variant="contained" color="secondary" className="call2action">Envoyer ma demande <FontAwesomeIcon icon={['fal', 'chevron-right']} size="1x" fixedWidth /></Button></div>
                            </Grid>
                            
                        </Grid>
                        </>
                        )}</>
                        )}
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <Paper variant="outlined" style={{ padding: '20px', marginBottom: '30px ', backgroundColor: '#F6F6F6'}}>
                            <div style={{ fontWeight: 'bold', marginBottom:'15px', textTransform:'uppercase', fontSize:'1rem', color: '#252C4C' }}>Nos bureaux</div>
                            <p>90 allée de la cannelle<br/>34980 SAINT-GELY DU FESC</p>
                        </Paper>

                        <Paper variant="outlined" style={{ padding: '20px', marginBottom: '30px ', backgroundColor: '#F6F6F6'}}>
                        <div style={{ fontWeight: 'bold', marginBottom:'15px', textTransform:'uppercase', fontSize:'1rem', color: '#252C4C' }}>Téléphone</div>
                            <p>Standard : +33(0)1.84.19.69.34</p>
                        </Paper>

                        <Paper variant="outlined" style={{ padding: '20px', marginBottom: '30px ', backgroundColor: '#F6F6F6'}}>
                            <div style={{ fontWeight: 'bold', marginBottom:'15px', textTransform:'uppercase', fontSize:'1rem', color: '#252C4C' }}>Siège social</div>
                            <p>IDEE-INNOVATION<br/><i>Service Comptin</i><br/>90 allée de la cannelle<br/>34980 SAINT-GELY DU FESC</p>
                        </Paper>
                    </Grid>
                </Grid>
                
                </Container>
            </div>
        </div>
    );
};

export default Contact;