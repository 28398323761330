import { Container } from '@mui/material';
import React from 'react';

const LegalConfidentialite = () => {

    document.title = 'Comptin - Politique de confidentialité & RGPD'

    const terms = {
        service: 'Comptin',
        raison_sociale: 'IDEE-INNOVATION',
        email: 'contact@comptin.fr',
        address: '90 allée de la cannelle',
        postal_code: '34980',
        locality: 'SAINT-GELY DU FESC'
    }

    return (
        <div>
             <div style={{ backgroundColor: '#252C4C', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h1>Politique de confidentialité &amp; RGPD</h1>
                <div className="sub_h1">
                    Nous protégeons votre vie privée - Traitement des données personnelles
                </div>
                
                </Container>
            </div>
            <Container style={{ padding:'20px'}}>
                <p>Les présentes dispositions ont pour objet d’informer les utilisateurs du présent site internet et les clients de {terms.service} sur la manière dont nous veillons à la protection de vos données et sur la manière dont nous collectons, utilisons et divulguons vos données personnelles.</p>
                <p>Vos données sont collectées et traitées lorsque vous visitez notre site internet, lorsque vous venez en rendez-vous, remplissez le formulaire de contact présent sur le site, complétez votre profil personnel sur le site, échangez avec nous par email, nous adressez votre candidature, lorsque vous accédez à nos services ou ceux de nos partenaires, ou bien encore lorsque vous participez à des événements de {terms.service}.</p>
                <p>Lorsque vous accédez à l’un de nos services, vous pouvez être amenés à fournir des données personnelles en remplissant des formulaires d’enregistrement ou en nous adressant des données par exemple, par mail, fax ou en rendez-vous.</p>
                <p>Conformément à la réglementation, nous ne traiterons, n'utiliserons et ne divulguerons vos données que dans la mesure où cela est nécessaire pour :<br/>
                <br/>
                    Traiter et répondre à une demande ou un devis.<br/>
                    Fournir un service ou une prestation et permettre ainsi la bonne gestion et le bon suivi du dossier que vous nous confiez.<br/>
                    Apporter une personnalisation dans le service rendu en fonction de vos intérêts ;<br/>
                    Répondre à vos demandes, recherches, commentaires et préoccupations ;<br/>
                    Assurer le fonctionnement et la sécurité du site, d'en améliorer le fonctionnement et de l’adapter à vos demandes.<br/>
                    Procéder à des calculs statistiques anonymisés.<br/>
                    Assurer le traitement des candidatures reçues.<br/>
                    Enquêter et permettre de prévenir des problèmes de sécurité, de fraude ou des problèmes techniques.<br/>
                    Protéger les droits, la propriété ou la sécurité de {terms.service}, des partenaires, des clients, employés, collaborateurs ou autres.<br/>
                </p>
                <p>
                    {terms.service} et ses partenaires peuvent également utiliser vos données à des fins de communication pour l'envoi de newsletters, des informations, et des invitations à des évènements et conférences.
                </p>
                <p>
                    Ces mesures de traitement et de divulgation de vos données personnelles ne peuvent être prises que dans le strict respect de vos intérêts ou vos libertés et droits fondamentaux quand ces derniers exigent une protection de vos données à caractère personnel supérieure à nos intérêts légitimes. Vous pouvez à tout moment retirer votre accord pour le traitement de vos données et/ou vous opposer à leur traitement.
                </p>
                <p>
                    Vos informations personnelles seront conservées aussi longtemps que nécessaire pour mener à bien le mandat qui nous a été confié, sauf si :
                    <br/>
                    Vous exercez votre droit de suppression des données vous concernant, dans les conditions décrites ci-après ;<br/>
                    Une durée de conservation plus longue est autorisée ou imposée en vertu d’une disposition légale ou règlementaire.<br/>
                    Les données personnelles utilisées à des fins de communication sont conservées pendant trois (3) ans à compter de votre dernier contact avec l’un des membres d’{terms.service} ou, à défaut de contact, à compter de leur collecte.<br/>
                </p>

                <p>Passés ces délais, les données personnelles vous concernant sont détruites.</p>

                <p>Pendant cette période, nous mettons en place tous moyens aptes à assurer la confidentialité et la sécurité de vos données personnelles, de manière à empêcher leur endommagement, effacement ou accès par des tiers non autorisés.</p>

                <p>Vos données personnelles peuvent être stockées et traitées sur les serveurs utilisés par les membres de {terms.service} ou sur les clouds sécurisés pour lesquels {terms.service} et/ou ses membres se sont assurés de la protection des données. Nous appliquons des mesures techniques et organisationnelles pour chercher à assurer un niveau de sécurité approprié au risque du traitement de données personnelles.</p>

                <p>L'accès à vos données personnelles est strictement limité à notre personnel administratif, nos employés et préposés et, le cas échéant, à nos sous-traitants. Les sous-traitants en question sont soumis à une obligation de confidentialité et ne peuvent utiliser vos données qu’en conformité avec nos dispositions contractuelles et la législation applicable. En dehors des cas énoncés ci-dessus, nous nous engageons à ne pas vendre, louer, céder ni donner accès à des tiers à vos données sans votre consentement préalable, à moins d’y être contraints en raison d’un motif légitime (obligation légale, lutte contre la fraude ou l’abus, exercice des droits de la défense, etc.).</p>

                <p>Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée et au Règlement européen n°2016/679/UE du 27 avril 2016, vous bénéficiez d’un droit d’accès, de rectification, de portabilité et d’effacement de vos données ou encore de limitation du traitement. Vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.</p>

                <p>Vous pouvez exercer vos droits en contactant directement {terms.service} par email à l’adresse suivante : {terms.email} ou par courrier recommandé avec accusé de réception à l’adresse suivante : {terms.raison_sociale} - {terms.service} – Service RGPD – {terms.address}, {terms.postal_code} {terms.locality}.</p>

                <p>Pour toute information complémentaire ou réclamation, vous pouvez contacter la Commission Nationale de l’Informatique et des Libertés (plus d’informations sur www.cnil.fr - adresse : 3 Place de Fontenoy, 75007 Paris).</p>


            </Container>
        </div>
    );
};

export default LegalConfidentialite;