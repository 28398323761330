import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Faq = () => {

    document.title = 'Comptin - FAQ - Foire aux questions'
    const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
    return (
        <>
        <div style={{ backgroundColor: '#252C4C', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h1>Foire Aux Questions</h1>
                <div className="sub_h1">
                    La réponse à vos questions est peut-être ici !
                </div>
                </Container>
            </div>
            <div style={{ backgroundColor: '#FFF', paddingTop: '50px', paddingBottom: '50px'}}>
            <Container>
                <h2 className="dark">Informations générales</h2>
                <div className="sub_h2 dark">À propos de l'utilisation de Comptin en général</div>
            
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                    <Typography style={{ fontWeight: '500', fontSize: '0.9rem'}}>
                        Comptin est-il un logiciel accessible en ligne ou en téléchargement ?
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography style={{ fontWeight: '300', fontSize: '0.9rem'}}>
                        C'est un logiciel SaaS (Software as a Service) hébergé dans le Cloud et déjà installé. Il est accessible 24h/24 et 7j/7 depuis le monde entier. 
                    </Typography>
                    </AccordionDetails>
                </Accordion>


            </Container>
            </div>
            <div style={{ backgroundColor: '#F6F6F6', paddingTop: '50px', paddingBottom: '50px'}}>
            <Container>
                <h2 className="dark">Encore une question !</h2>
                <div className="sub_h2 dark">Vous avez une question qui n'est pas présente dans la liste ?</div>
                <div>
                    <p style={{ textAlign: 'center'}}><Link to="/contact"><Button variant="contained" color="secondary" className="call2action">Posez votre question <FontAwesomeIcon icon={['fal', 'chevron-right']} size="1x" fixedWidth /></Button></Link></p>
                </div>
            </Container>
            </div>
        </>
    );
};

export default Faq;