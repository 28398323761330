import { Button, Container, Divider, Grid, Paper } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Applications = () => {

    document.title = 'Comptin - ERP et applications en fonction de vos besoins'

    return (
        <div>
            <div style={{ backgroundColor: '#252C4C', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h1>Applications</h1>
                <div className="sub_h1">
                    De multiples applications au service de votre entreprise
                </div>
                </Container>
            </div>
            <div style={{ backgroundColor: '#FFF', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                    <Grid container spacing={3}>
                    <Grid item sm={6} className="flex items-center">
                            <div className="w-full h-full flex-1 justify-center">
                                <ApplicationBlock 
                                    title="Production comptable"
                                    icon="analytics"
                                    color="#F57F17"
                                >
                                    <p>Enregistrez vos factures d'achats et de ventes, vos paiements et synchronisez les avec la comptabilité.</p>
                                    <p>Pilotez votre activité en suivant vos encaissements clients et votre prévisionnel de trésorerie.</p>
                                </ApplicationBlock>
                            </div>
                        </Grid>
                        <Grid item sm={6} className="flex items-center">
                            <div className="w-full h-full flex-1 justify-center">
                                <ApplicationBlock 
                                    title="Service E-mailing"
                                    icon="paper-plane"
                                    color="#1C4ED8"
                                >
                                    <p>Créez vos liste de destinataires, préparez et envoyez vos campagnes e-mailing avec un fort taux de déliverabilité.</p>
                                    <p>Gérez vos mails transactionnels via une API et obtenez un suivi des ouvertures et clics de vos prospects et clients directement sur Comptin.</p>
                                </ApplicationBlock>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

const ApplicationBlock = props => {
    const { title, icon, color } = props;

    return (
        <Paper variant="outlined" className="h-full" >
            <div className="font-bold text-left bg-gray-200 p-3" style={{ fontSize:'1.2rem'}}>{title}</div>
            <Grid container spacing={0} className="bg-gray-100 h-full">
                <Grid item sm={2} className="flex items-center">
                    <div className="w-full flex-1 justify-center">
                        <div className=" text-center" style={{ padding:'20px 0px'}}>
                            <FontAwesomeIcon icon={['fal', icon]} size="3x" style={{ color }} fixedWidth />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={10} className="flex items-center">
                    <div className="w-full p-3 flex-1 justify-center">
                        {props.children}
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Applications;