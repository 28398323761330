import React from 'react';
import { Button, Grid, Container, Rating, Paper, Avatar, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typewriter from '../components/utils/Typewriter';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';

const Home = () => {

    document.title = 'Comptin - Gérez votre entreprise comme un jeu d\'enfant et collaborez avec votre comptable'

    return (
        <div id="page-home">
           <div className="header">
               <Container maxWidth="xl">
                   <Grid container spacing={3}>
                        <Grid item md={5} sm={12} xs={12} className="flex items-center">
                        <div className="w-full flex-1 justify-center">
                            <h1>Pilotez votre entreprise<br/><span>  </span></h1>
                            <Typewriter
                                rotateSpeed={1000}
                                typeSpeed={150}
                                className="typewriter"
                                dataText={["comme un jeu d'enfant", 'en toute simplicité', 'avec votre comptable', "de façon intuitive", 'avec le logiciel Comptin']}
                            />
                            <div className="summary">Un logiciel de gestion d'entreprise unique et modulable connecté en temps réel à votre expert comptable. Suivez et pilotez votre activité en restant concentré sur la satisfaction de vos clients</div>
                            <div><Scroll.Link to="solution" spy smooth offset={-80} duration={1000} delay={0}><Button variant="contained" color="secondary" size="large" className="bg-red-500 call2action">Découvrir Comptin <sup>&copy;</sup> &nbsp; <FontAwesomeIcon icon={['fal', 'chevron-down']} size="1x" /></Button></Scroll.Link></div>
                            </div>
                        </Grid>
                        <Grid item md={1} sm={12} xs={12} className="flex items-center">
                            </Grid>
                        <Grid item md={6} sm={12} xs={12} className="flex items-center">
                            <div className="w-full flex-1 justify-center text-center">
                                <img src="images/screens/home-comptin.png" />
                            </div>
                        </Grid>
                    </Grid>
            </Container>
           </div>
           <div id="solution" style={{ backgroundColor: '#FFF', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h2 className="dark text-center">L'ERP connecté à votre comptable</h2>
                <div className="sub_h2 dark" style={{ textAlign: 'center'}}>
                    Gérez votre entreprise en temps réel avec comptable 
                </div>
                <Grid container spacing={3}>
                    <Grid item md={4} sm={6} xs={12} className="flex items-top">
                        <SquareBlock 
                            icon="globe"
                            title="Accessible partout"
                            description="Plateforme accessible 24h/24 et 7j/7 depuis le monde entier."
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="flex items-top">
                        <SquareBlock 
                            icon="file-invoice"
                            title="L'efficacité avant tout"
                            description="Déposez vos pièces comptables et profitez de la saisie automatisée."
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="flex items-top">
                        <SquareBlock 
                            icon="boxes"
                            title="Modulable et évolutif"
                            description="Ajoutez les modules qui vous intéresse comme la facturation ou demandez nous les outils de demain"
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="flex items-top">
                        <SquareBlock 
                            icon="mobile"
                            title="Mobile ready"
                            description="Pilotez votre entreprise directement depuis votre mobile"
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="flex items-top">
                        <SquareBlock 
                            icon="file-pdf"
                            title="Intégration des Factur-X"
                            description="Aux normes pour la lecture des Factur-X et la création automatique des achats"
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="flex items-top">
                        <SquareBlock 
                            icon="credit-card"
                            title="Suivez vos paiements"
                            description="Enregistrez vos règlements directement depuis Comptin. Virement SEPA, fichiers de prélèvements."
                        />
                    </Grid>
                </Grid>
                <div className="text-center" style={{ paddingTop:'50px'}}>
                    <Link to={`/fonctionnalites`}>
                        <Button variant="contained" color="secondary" className="call2action">Découvrir toutes les fonctionnalités <FontAwesomeIcon icon={['fa', 'chevron-right']} size="1x" fixedWidth /></Button>
                    </Link>
                </div>
                </Container>
            </div>
           
           <div style={{ backgroundColor: '#F6F6F6', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h2 className="dark">Noté 5 étoiles par nos clients</h2>
                <div className="sub_h2 dark">
                    Des entreprises et cabinets comptables de la France entière partagent une expérience unique sur Comptin <sup>&copy;</sup>
                    <Grid container spacing={3} style={{marginTop:'30px', textAlign:'left'}} alignItems="top" justify="center">
                        <Grid item md={4} sm={6} xs={12} className="flex items-top">
                            <ReviewBlock 
                                stars={5}
                                name="Société FORMAUDIT"
                                activite="Formation et enseignement"
                                avatar="images/avatars/formaudit.png"
                                review="Avec Comptin nous gagnons du temps dans le suivi de notre comptabilité au jour le jour. Avant nous avions du retard dans les encaissements de nos clients, désormais cette problèmatique n'existe plus."
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} className="flex items-top">
                            <ReviewBlock 
                                stars={5}
                                name="EURL SVETI - STEFAN"
                                activite="Ingénierie, études techniques"
                                avatar="images/avatars/svetistefan.jpg"
                                review="Fini la paperasse interminable et le délai de réponse de plusieurs semaines avec mon comptable. Maintenant j'ai tout ce dont j'ai besoin pour mon entreprise sur Comptin et je dégage du temps pour mes loisirs"
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} className="flex items-top">
                            <ReviewBlock 
                                stars={5}
                                name="EXPERTISE COMPTABLE CHARLE ET ASSOCIES"
                                activite="Cabinet d'expertise comptable"
                                avatar="images/avatars/charleetassocies.png"
                                review="Ce logiciel nous permet d'être en avance dans la réalisation des bilans de nos clients car l'intégralité des pièces sont présentes pour chacune des societés avec lesquelles nous travaillons"
                            />
                        </Grid>
                    </Grid>
                    
                </div>
                </Container>
            
           

           </div>

        </div>
    );
};

const SquareBlock = props => {

    const { icon, title, description } = props;

    return (
        <Paper style={{ padding:'20px', textAlign:'center'}} variant="outlined" >
            <div><FontAwesomeIcon icon={['fal', icon]} size="3x" className="text-secondary" fixedWidth /></div>
            <div className="text-primary" style={{ fontSize:'1.2rem', margin:'20px 0px'}}>{title}</div>
            <div>{description}</div>
        </Paper>
    )
}

const ReviewBlock = props => {

    const { review, stars, name, avatar, activite } = props;

    return (<Paper variant="outlined" style={{padding:'20px'}} >
    <div style={{marginBottom: '20px'}}><Rating size="large" value={stars} readOnly /></div>
    <p style={{ minHeight:'150px'}}><sup><FontAwesomeIcon icon={['fa', 'quote-left']} size="1x" fixedWidth /></sup> {review} <sub><FontAwesomeIcon icon={['fa', 'quote-right']} size="1x" fixedWidth /></sub></p>
    <Divider style={{ margin: '15px 0px'}}/>
    <Grid container spacing={3}>
        <Grid item sm={3} xs={4} className="flex items-center">
        <div className="w-full flex-1 justify-center">
            <Avatar alt={name} src={avatar} sx={{ width: 70, height: 70 }}/>
        </div>
        </Grid>
        <Grid item sm={9} xs={8} className="flex items-center">
            <div className="w-full flex-1 justify-center">
                <div style={{ fontSize:'0.9rem', fontWeight:'bold'}}>{name}</div>
                <div style={{ fontSize:'0.9rem', fontWeight:'normal'}}>{activite}</div>
            </div>
        </Grid>
    </Grid>
</Paper>)
}

export default Home;