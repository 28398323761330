import React, { useState } from 'react';
import { AppBar, Menu, MenuItem, Fade, Toolbar, IconButton, Button, Typography, SwipeableDrawer, List, ListItem, ListItemIcon, ListItemText, Divider, Hidden } from '@mui/material';
import logo from '../../assets/logos/comptin-text-white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/system';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link, useLocation } from 'react-router-dom';

const TopNavbar = () => {
  const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }
    
        setIsDrawerOpen(open);
      };
    

    return (
        <>
        <AppBar className="top-bar" position="fixed" style={{ zIndex: 1400, border:'0px', boxShadow:'none'}}>
        <Toolbar>
        <Link to='/'>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <img src={logo} className="top-bar-logo" alt="Comptin" />
          </IconButton></Link>
          <Typography component="div" sx={{ flexGrow: 1, textAlign:'center' }}>
          <Hidden lgDown>
            <Link className={`link ${location.pathname === '/fonctionnalites' ? 'active' : ''}`} to='/fonctionnalites'>Fonctionnalités</Link>
            {/* <Link className={`link ${location.pathname === '/applications' ? 'active' : ''}`} to='/applications'>Applications</Link> */}
            <Link className={`link ${location.pathname === '/experts-comptables' ? 'active' : ''}`} to="/experts-comptables">Experts-comptables</Link>
            {/* <Link className={`link ${location.pathname === '/pricing' ? 'active' : ''}`} to="/pricing">Tarifs</Link> */}
            <Link className={`link ${location.pathname === '/faq' ? 'active' : ''}`} to="/faq">FAQ</Link>
            <Link className={`link ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact">Tarifs &amp; Contact</Link>
            </Hidden>
          </Typography>
          <Hidden lgDown><Button color="secondary" size="medium" variant="contained" href="https://app.comptin.fr" target="_blank">Connexion &nbsp;<FontAwesomeIcon icon={['fal', 'sign-in']} size="1x" /></Button></Hidden>
          <Hidden lgUp>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          >
            <FontAwesomeIcon icon={['fal', 'bars']} size="1x" />
          </IconButton>
          </Hidden>
        </Toolbar>
        
      </AppBar>
      <SwipeableDrawer
      anchor="top"
      open={isDrawerOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      >
      <Box
role="presentation"
style={{ backgroundColor: '#252C4C', height:'100vh', color:'#FFF'}}
onClick={toggleDrawer(false)}
onKeyDown={toggleDrawer(false)}
>
<List style={{ marginTop: '56px'}}>
<Link className={`link ${location.pathname === '/fonctionnalites' ? 'active' : ''}`} to='/fonctionnalites'><ListItem button>
      <ListItemText primary={"Fonctionnalités"} />
    </ListItem></Link>
    <Link className={`link ${location.pathname === '/experts-comptables' ? 'active' : ''}`} to="/experts-comptables"><ListItem button>
      <ListItemText primary={"Experts-comptables"} />
    </ListItem></Link>
    <Link className={`link ${location.pathname === '/pricing' ? 'active' : ''}`} to="/pricing"><ListItem button>
      <ListItemText primary={"Tarifs"} />
    </ListItem></Link>
    <Link className={`link ${location.pathname === '/faq' ? 'active' : ''}`} to="/faq"><ListItem button>
      <ListItemText primary={"FAQ"} />
    </ListItem></Link>
    <Link className={`link ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact"><ListItem button>
      <ListItemText primary={"Contact"} />
    </ListItem></Link>
    <ListItem button>
      <Button variant="contained" color="secondary"  href="https://gestion.comptin.fr" target="_blank">Mon compte</Button>
    </ListItem>
</List>
</Box>
      </SwipeableDrawer>
      </>
    );
};

export default TopNavbar;