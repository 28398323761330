import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Text2Speech = props => {

    const { variant, title, textValue, color } = props;

    var synth = window.speechSynthesis;
    var voices = synth.getVoices();

    const [voiceValue] = useState('fr-FR');
    const [pitchValue] = useState(1);
    const [rateValue] = useState(1);

    useEffect(() => {
        synth.cancel();
    },[textValue])

    const [state, setState] = useState('cancel');

    const play = () => {
        if (synth.speaking) {
            console.error('speechSynthesis.speaking');
            return;
        }
        var utterThis = new SpeechSynthesisUtterance(textValue);
        utterThis.onend = function (event) {
            console.log('SpeechSynthesisUtterance.onend');
        }
        utterThis.onerror = function (event) {
            console.error('SpeechSynthesisUtterance.onerror');
        }
        utterThis.onpause = function (event) {
            setState('pause');
        }
        utterThis.onstart = function (event) {
            setState('speaking');
        }
        utterThis.onresume = function (event) {
            setState('speaking');
        }
        utterThis.onend = function (event) {
            setState('cancel');
        }
        utterThis.voice = voices[voiceValue];
        utterThis.pitch = pitchValue;
        utterThis.rate = rateValue;
        synth.speak(utterThis);
        setState('speaking')
    }

    const pause = () => {
        synth.pause(); 
    }

    const resume = () => {
        synth.resume();
    }

    const cancel = () => {
        synth.cancel();
    }

    return (
        <div>
            <ButtonGroup variant={variant ?? "contained"} color={color ?? "secondary"} className="m-0">
                {title ? (
                <Button>{title}</Button>
                ) : ''}
                {state === 'cancel' ? (
                    <Tooltip title="Lecture">
                    <Button onClick={() => play()} ><FontAwesomeIcon icon={['fa', 'play']} size="1x" fixedWidth /></Button>
                    </Tooltip>
                ) : (state === 'speaking') ? (
                    <Tooltip title="Pause">
                    <Button onClick={() => pause()} ><FontAwesomeIcon icon={['fal', 'pause']} size="1x" fixedWidth /></Button>
                    </Tooltip>
                ) : (state === 'pause') ? (
                    <Tooltip title="Continuer">
                    <Button onClick={() => resume()} ><FontAwesomeIcon icon={['fal', 'play']} size="1x" fixedWidth /></Button>
                    </Tooltip>
                ) : (
                    ''
                )}
                <Tooltip title="Stop">
                    <Button onClick={() => cancel()}><FontAwesomeIcon icon={['fa', 'stop']} size="1x" fixedWidth /></Button>
                </Tooltip>
                
            </ButtonGroup>
        </div>
    );
};

export default Text2Speech;