import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div id="footer">
            Comptin &copy; 2021 - Tous droits réservés | <Link to="/legals/confidentialite">Politique de confidentialité</Link> | <Link to="/contact">Contact</Link>
        </div>
    );
};

export default Footer;