import { Container, Button, Paper, Grid, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ButtonGroup, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AmountHelper from '../helpers/AmountHelper';
import * as _ from 'lodash';
import axios from 'axios';
import isEmpty from 'is-empty'

const useStyles = makeStyles({
    root: {
      '& .bg-odd': {
        backgroundColor: '#F0F0F0',
        color: '#1a3e72',
      },
      '& .bg-even': {
        backgroundColor: '#F6F6F6',
        color: '#1a3e72',
      },
      '& .header': {
          textTransform:'uppercase',
          fontSize: '0.75rem',
      }
    },
  });

// const urlBase = 'http://localhost:8080';
const urlBase = 'https://api.comptin.fr';

const renderVolumeTagText = (volumeTag, bPlural) => {
    switch (volumeTag) {
        case 'access':
        case 'account_access':
            return 'accès';
        case 'go':
            return 'giga';
        case 'note':
            return bPlural ? 'pièces' : 'pièce';
        case 'analyze':
            return bPlural ? 'pages' : 'page';
        case 'account':
            return bPlural ? 'comptes' : 'compte';
        case 'receipt':
            return bPlural ? 'reçus' : 'reçu';
        default:
            return volumeTag;
    }
};

const Pricing = () => {
    document.title = 'Une tarification simple et adaptée - Indépendants, TPE ou PME'


    const [storePackageTypes, setStorePackageTypes] = useState(null);
    const [storePackages, setStorePackages] = useState(null);
    const [storeApplicationServices, setStoreApplicationServices] = useState(null);
    useEffect(() => {
        axios.get(urlBase+'/store/package_types').then(response => {
            setStorePackageTypes(response.data.data);
        });
        axios.get(urlBase+'/store/packages').then(response => {
            setStorePackages(response.data.data);
        });
        axios.get(urlBase+'/store/application_services').then(response => {
            setStoreApplicationServices(_.orderBy(response.data.data,['position'],['asc']));
        })
    },[])
    
    const [storePackageTypeSelected, setStorePackageTypeSelected] = useState(null);
    useEffect(() => {
        if (!isEmpty(storePackageTypes) && isEmpty(storePackageTypeSelected)) {
            setStorePackageTypeSelected(storePackageTypes[0]);
        }
    },[storePackageTypes, storePackageTypeSelected])
    

    
    

    

    
    
    return (
        <div>
            <div style={{ backgroundColor: '#252C4C', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h1>Une tarification simple et adaptée</h1>
                <div className="sub_h1">
                    Découvrez les forfaits : Indépendants, TPE ou PME
                </div>
                
                </Container>
                <Container>
                    <div style={{textAlign: 'center'}}>
                        {storePackageTypes && storePackageTypes.map(storePackageType => {
									return (
                                        <Button 
                                            key={storePackageType.id}
                                            variant="contained"
                                            size="large"
                                            style={{
                                                margin: '10px 10px'
                                            }}
                                            color={
												storePackageTypeSelected &&
												storePackageTypeSelected.id === storePackageType.id
													? 'secondary'
													: 'inherit'
											}
                                            onClick={() => setStorePackageTypeSelected(storePackageType)}
                                        ><div className="font-bold text-16">
                                        {storePackageType.name}{' '}
                                        <br/><span style={{ fontSize: '0.7rem'}}>
                                            {storePackageType.subtitle}
                                        </span>
                                    </div></Button>
									);
								})}
                       
                            
                    </div>
                    
                    <Grid container spacing={5} style={{ marginTop: '0px'}} alignItems="center" justifyContent="center">
                        {storePackages && storePackageTypeSelected && storePackages.filter(x => x.StorePackageType.id === storePackageTypeSelected.id).map(storePackage => {
                            return (
                                <Grid key={storePackage.id} item md={4} sm={6} xs={12}>
                                    <PricingPackage
                                        storePackage={storePackage}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <div style={{ color:'#FFF', fontSize:'1rem', textAlign:'center', margin: '10px', fontWeight:'normal', fontStyle:'italic' }}>Offres sans engagement de durée</div>
                </Container>
            </div>
            <div style={{ backgroundColor: '#FFF', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
               
                <div className="sub_h1 dark">
                    Vous dépassez votre forfait mensuel ?
                </div>
                </Container>
                <Container>
                    <Grid container spacing={5} style={{ alignItems:'center', justifyContent: 'center' }}>
                        <Grid item lg={7} md={9} sm={10} xs={12}>
                        {storeApplicationServices && storeApplicationServices.map(storeApplicationService => {
                            return (
                                
                                    <Paper  key={storeApplicationService.id} variant="outlined" style={{ backgroundColor: '#F7FAFC', padding:'15px', height:'100%', marginBottom: '15px'}}>
                                        <Grid container spacing={3} style={{ alignItems:'center', justifyContent: 'center' }}>
                                            <Grid item sm={2} style={{ flex:'1 1 auto', alignItems:'center' }}>
                                                <div style={{ flex:'1 1 0%', width:'100%', justifyContent:'center', textAlign: 'center' }}>
                                                    <FontAwesomeIcon
                                                        style={{ color: storeApplicationService.color }}
                                                        icon={['fal', storeApplicationService.icon]}
                                                        size="2x"
                                                        fixedWidth
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item sm={7} style={{ flex:'1 1 auto', alignItems:'center' }} >
                                                <div style={{ flex:'1 1 0%', width:'100%', justifyContent:'center', textAlign: 'left' }}>
                                                    <div style={{ fontWeight:'bold', fontSize:'1rem', textAlign: 'left'}}>{storeApplicationService.title}</div>
                                                    <div style={{ fontWeight:'normal', fontSize:'0.9rem', fontStyle:'italic'}}>{storeApplicationService.subtitle}</div>
                                                </div>
                                            </Grid>
                                       
                                            <Grid item sm={3} style={{ flex:'1 1 auto', alignItems:'center' }} >
                                                <div style={{ flex:'1 1 0%', width:'100%', justifyContent:'center' }}>
                                                    {storeApplicationService.price_ht === 0 ? (
                                                        <div style={{ textAlign:'right', fontSize:'1rem', lineHeight:'0.8rem' }}>OFFERT</div>) : (
                                                    <div style={{ textAlign:'right', fontSize:'1rem', lineHeight:'1rem' }}>{AmountHelper.display(storeApplicationService.price_ht, 2)} €<br/>
                                                        <span style={{ textAlign:'right', fontSize:'0.6rem', lineHeight:'0.8rem' }}>/ {storeApplicationService.volume > 1 ? storeApplicationService.volume : ''} {renderVolumeTagText(
                                                        storeApplicationService.volume_tag,
                                                        storeApplicationService.volume >
                                                            1
                                                    )} {storeApplicationService.duration_interval === 'month' ? '/ mois': ''} </span></div>
                                                        )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                
                            )
                        })}
                        </Grid>
                    </Grid>
                {/* <div style={{ minHeight: '200px', width: '100%' }} className={classes.root}>
					<DataGrid
						autoHeight
                        hideFooter={true}
						rows={dataRows}
                        columns={columns}
						localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
					/>
				</div> */}
                    
                </Container>
            </div>
            {/* <div style={{ backgroundColor: '#252C4C', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h1>Offres cabinets comptables</h1>
                <div className="sub_h1">
                    Des tarifs adaptés à vos volumes vous permettant d'automatiser les petits dossiers comme les gros
                </div>
                <div style={{ color:'#FFF', fontSize:'1rem', textAlign:'center', margin: '10px', fontWeight:'normal', fontStyle:'italic' }}>Offres sans engagement : vous allez économiser tellement de temps que vous allez rester !</div>
                </Container>
                <Container>
                    <Grid container spacing={5} style={{ marginTop: '0px'}} alignItems="center" justifyContent="center">
                        {offerPackages && offerPackages.slice(3,6).map(offerPackage => {
                            return (
                                <Grid key={offerPackage.id} item md={4} sm={6} xs={12}>
                                    <PricingPackage
                                        offerPackage={offerPackage}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </div> */}
            {/* <div style={{ backgroundColor: '#FFF', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h1>Autres services proposés</h1>
                <div className="sub_h1 dark">
                    Comptin ne s'arrête pas à la comptabilité ! Nous avons des services accessibles sur demande
                </div>
                </Container>
                <Container>
                    <p><b>Service CONTACT</b> : Un service de gestion de contacts vous permettant de regrouper tous vos clients, fournisseurs, associés, salariés, prospect et de regrouper toutes les informations associées pour un meilleur suivi.</p>
                    <p><b>Service E-MAILING</b> : Un service vous permettant d'envoyer vos campagnes e-mailing en toute simplicité et de préparer des mails transactionnels qui seront utilisés par votre site internet afin d'obtenir des statistiques détaillées sur les taux d'ouverture, clics de vos envois</p>
                    <p><b>Service GESTION COMMERCIALE</b> : Un service vous permettant de créer vos devis, bons de commande, bons de livraison et factures pour vos clients à partir de votre catalogue de produits et services.</p>
                    <p><b>Service E-COMMERCE</b> : Un service vous permettant d'intégrer en temps réel votre catalogue produit sur votre site internet. Vous pourrez ainsi vendre directement vos articles en ligne et obtenir le suivi de vos stocks en temps réel sur Comptin.</p>
                    <p><b>Service CAISSE</b> : Un service vous permettant d'enregistrer votre caisse (tickets restaurants, espèces, produits vendus...) à la journée et d'obtenir le brouillard de caisse. La caisse sera automatiquement synchronisée avec votre comptabilité ce qui vous permettra d'effectuer vos contrôles en temps réel.</p>
                    <div style={{ textAlign: 'center', marginTop:'30px' }}>
                        <Link to={`/contact?service=commercial`}><Button variant="contained" color="secondary">Demander plus d'informations ?</Button></Link>
                    </div>
                </Container>
            </div> */}
        </div>
    );
};

const PricingPackage = props => {
	const { storePackage } = props;

    

	return (
		<>
			{storePackage ? (
				<Paper className="pricing-package" variant="elevation" style={{borderRadius: '20px'}}>
                    <div style={{
                        padding:'20px',
                        backgroundColor: '#F7FAFC',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px'
                    }}>
					<Grid container spacing={3} style={{ alignItems:'center', justifyContent: 'center' }}>
                    <Grid item xs={2} style={{ flex:'1 1 auto', alignItems:'center' }}>
                    <div style={{ flex:'1 1 0%', width:'100%', justifyContent:'center' }}>
                            <FontAwesomeIcon
                                    style={{ color: storePackage.color }}
                                    icon={['fal', storePackage.icon]}
                                    size="2x"
                                    fixedWidth
                                />
							</div>
						</Grid>
                    
						<Grid item xs={7} style={{ flex:'1 1 auto', alignItems:'center' }}>
                        <div style={{ flex:'1 1 0%', width:'100%', justifyContent:'center' }}>
								<div style={{
                                    fontSize:'1rem',
                                    fontWeight: 'bold',
                                }}>{storePackage.title}</div>
								<div style={{
                                    fontSize:'0.8rem',
                                    fontWeight: 'normal',
                                }}>{storePackage.subtitle}</div>
							</div>
						</Grid>
						<Grid item xs={3} style={{ flex:'1 1 auto', alignItems:'center' }}>
                        <div style={{ flex:'1 1 0%', width:'100%', justifyContent:'center' }}>
								<div className="price">
									{AmountHelper.display(
										storePackage.price_ht,
										0
									)}{' '}
									<span>€</span>
								</div>
								<div className="sub_price">HT / mois</div>
							</div>
						</Grid>
					</Grid>
					</div>
					
                        {storePackage.StorePackageServices.map((StorePackageService, index) => {
                            return (
                                <Paper
                                    key={StorePackageService.id}
                                    square
                                    variant="outlined"
                                    style={{
                                        padding:'10px'
                                    }}
                                   // className={`border-0 border-b-1 ${index % 2 !== 0 ? 'bg-gray-100' : 'bg-gray-50'}`}
                                >
                                    <Grid container spacing={3} alignItems="center" justify="center">
                                        <Grid item xs={9} className="flex items-center">
                                        <div className="w-full flex-1 justify-center">
                                            <div style={{
                                                fontSize: '0.9rem',
                                                fontWeight: 'bold',
                                                lineHeight: '1.2rem',
                                                marginBottom: '4px'
                                            }}> {StorePackageService.StoreApplicationService.title}</div>
                                            <div style={{
                                                fontSize: '0.7rem',
                                                lineHeight: '0.9rem',
                                                fontStyle: 'italic',
                                            }}>{StorePackageService.StoreApplicationService.subtitle}</div>
                                        </div>
                                        </Grid>
                                        <Grid item xs={3} className="flex items-center">
                                        <div className="w-full flex-1 justify-center">
                                            <div className="font-bold text-14 text-left">
                                                {StorePackageService.quantity *
                                                    StorePackageService.StoreApplicationService.volume}{' '}
                                                <span style={{
                                                    fontWeight: 'normal',
                                                    fontSize: '11px',
                                                    color:'#999'
                                                }}>
                                                    &nbsp;
                                                    {renderVolumeTagText(
                                                        StorePackageService.StoreApplicationService.volume_tag,
                                                        StorePackageService.quantity *
                                                            StorePackageService.StoreApplicationService.volume >
                                                            1
                                                    )}
                                                </span>
                                            </div></div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            );
                        })}
                    <div style={{
                        padding:'20px',
                        textAlign: 'center',
                        backgroundColor: '#F7FAFC',
                        borderBottomLeftRadius: '20px',
                        borderBottomRightRadius: '20px'
                    }}>
                        <Link href={`https://gestion.comptin.fr/register?service=demo&version=${storePackage.title}`} target="_blank" style={{textDecoration: 'none'}}><Button variant="contained" color="secondary">Tester gratuitement<br/>7 jours</Button></Link>
                    </div>
				</Paper>
			) : (
				''
			)}
		</>
	);
};

// const PricingPackage = props => {

//     const { title, subtitle, price, services, typePrice, discountOptions } = props;

//     const listServices = [
//         {
//             tag: 'organisation',
//             icon: 'boxes',
//             color: '#252C4C',
//             title: "Dossiers entreprise",
//             subtitle: null
//         },
//         {
//             tag: 'accounting',
//             icon: 'analytics',
//             color: '#F57F17',
//             title: "Production comptable",
//             subtitle: null
//         },
//         {
//             tag: 'bank',
//             icon: 'piggy-bank',
//             color: '#43A047',
//             title: "Rapprochement bancaire",
//             subtitle: null
//         },
//         {
//             tag: 'ocr',
//             icon: 'print',
//             color: '#BE185D',
//             title: "Scanner intelligent",
//             subtitle: null
//         },
//         {
//             tag: 'expense',
//             icon: 'receipt',
//             color: '#5B21B6',
//             title: "Notes de frais",
//             subtitle: null
//         },
//         {
//             tag: 'document',
//             icon: 'folder-open',
//             color: '#1C4ED8',
//             title: "Archivage des documents",
//             subtitle: null
//         },
//         {
//             tag: 'user',
//             icon: 'users',
//             color: '#1F2937',
//             title: "Utilisateurs entreprises et cabinets",
//             subtitle: null
//         },
//         {
//             tag: 'offer',
//             icon: 'plus-circle',
//             color: '#252C4C',
//             title: "Services complémentaires",
//             subtitle: null
//         },
        
//     ]

//     const [listServiceUpdate, setListServiceUpdate] = useState(listServices);
//     useEffect(() => {
//         if (services) {
//             let listServicesTmp = listServices.slice();
//             services.forEach(service => {
//                 let listServiceTmp = listServicesTmp.find(x => x.tag === service.tag);
//                 if (typePrice === 'yearly' && service.subtitle_yearly) {
//                     listServiceTmp.subtitle = service.subtitle_yearly;
//                 } else {
//                     listServiceTmp.subtitle = service.subtitle;
//                 }
//                 if (typePrice === 'yearly' && service.more_yearly) {
//                     listServiceTmp.more = service.more_yearly;
//                 } else {
//                     listServiceTmp.more = service.more;
//                 }
//             })
//             setListServiceUpdate(listServicesTmp);
//         }
//     }, [services])

//     return <Paper className="pricing-package" variant="outlined">
//         <Grid container spacing={3}>
//             <Grid item xs={7} className="flex items-center">
//             <div className="w-full flex-1 justify-center">
//             <h2>{title}</h2>
            
            
//         </div>
//         </Grid>
//         <Grid item xs={5} className="flex items-center">
//         <div className="w-full flex-1 justify-center">
//             <div className="price">{price} <span>€</span></div>
//             <div className="sub_price">HT / mois</div>
//             </div>
//         </Grid>
//         </Grid>
        
//         <div style={{ marginTop:'10px', fontSize:'0.8rem', lineHeight:'1rem', padding:'0px 20px', marginBottom:'20px'}}>{subtitle}</div>
//         <Divider style={{ marginTop: '10px', marginBottom: '10px'}} />
//         <div style={{ fontSize:'0.8rem', lineHeight:'1rem', textAlign:'center', padding:'0px 20px', fontWeight:'bold'}}>{discountOptions === 0 ? 'Pas de remises sur les options' : `- ${discountOptions} % de remise sur les options`}</div>
//         <Divider style={{ marginTop: '10px', marginBottom: '10px'}} />
//         <div style={{minHeight:'250px', padding:'0px'}}>
//         <List>
//             {listServiceUpdate.map(service => {
//                 return (
//                     <ListItem key={service.tag} disablePadding dense>
//                         <ListItemButton style={{fontSize:'26px', color: service.color}}>
//                             <FontAwesomeIcon icon={['fal', service.icon]} size="1x" fixedWidth />
//                         <ListItemText sx={{ marginLeft: '20px'}} primary={service.title} secondary={service.subtitle} />
                        
//                         </ListItemButton>
//                     </ListItem>
//                 )
//             })}
//           </List>
//         </div>
//         <Divider style={{ marginTop: '10px', marginBottom: '10px'}} />
//         <div style={{ textAlign: 'center', margin:'20px' }}>
//             <Link to={`/contact?service=demo&version=${title}`}><Button variant="contained" color="secondary">Demander un essai</Button></Link>
//         </div>
// </Paper>
// }

export default Pricing;