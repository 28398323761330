import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-124528746-17');

const GAnalytics = () => {

    const location = useLocation();
    useEffect(() => {
        if (location) {
            ReactGA.pageview(location.pathname);
        }
    },[location])

    return (
        <div>
            
        </div>
    );
};

export default GAnalytics;