import { Button, Container, Grid } from '@mui/material';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text2Speech from '../components/utils/Text2Speech';


const ExpertsComptables = () => {

    document.title = 'Comptin - La solution dédiée aux experts comptables et à leurs clients'
      
    const [disabled, setDisabled] = useState(false);
    return (
        <div>
            
            <div style={{ backgroundColor: '#252C4C', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <h1>Experts-comptables</h1>
                <div className="sub_h1">
                    Une solution élaborée avec des experts et pensée pour les cabinets comptables
                </div>
                <div className="text-center">
                    <Text2Speech 
                        title="Résumé audio"
                        color="secondary"
                        variant="outlined"
                        textValue="Compte in est une solution élaborée avec des experts comptable et pensé pour les cabinets. 
                        Compte in permet de collaborer à distance avec vos clients. Vos clients peuvent y déposer leurs pièces comptables au jour le jour, et piloter sereinement leur activité. 
                        La saisie des opérations comptables est automatisée et vous retrouvez l'intégralité des factures et avoir fournisseurs et clients ainsi que les relevés bancaires sur Compte In.
                        Compte In contrôle les écritures comptables et vous n'avez plus qu'à exporter ces écritures au format de votre choix. Ne perdez plus votre temps à saisir, utilisez Compte in et offrez un service à valeur ajoutée à vos clients."
                    />
                    </div>
                </Container>
            </div>
            <div style={{ backgroundColor: '#FFF', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item sm={5} className="flex items-center">
                            <div className="w-full flex-1 justify-center">
                                <img src="images/bg/expert/expert-clients.png" style={{ width: '100%'}} />
                            </div>
                        </Grid>
                        <Grid item sm={7} className="flex items-center">
                            <div className="w-full flex-1 justify-center">
                                <h2 className="dark">Comptin accélère les échanges avec vos clients</h2>
                                <div className="sub_h2 dark">
                                    Un logiciel unique qui vous permet de collaborer à distance avec vos clients
                                </div>
                                <p className="text-justify">
                                    Aucune configuration requise, vous importez les fichiers FEC (contenant les écritures comptables) et Comptin configure automatiquement le dossier de votre client (détection des fournisseurs, clients, banques, associés...).
                                </p>                                import Text2Speech from '../components/utils/Text2Speech';

                                <p className="text-justify">
                                    Comptin est disponible en marque blanche : vous pouvez offrir à vos clients une version de la plateforme à votre marque. Ils se connecteront par exemple sur gestion.nomducabinet.com et utilisera votre solution hébergée avec vos couleurs et votre logo.
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                
                </Container>
            </div>
            <div style={{ backgroundColor: '#F6F6F6', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                    <Grid container spacing={3} direction="row-reverse">
                        <Grid item sm={5} className="flex items-center">
                            <div className="w-full flex-1 justify-center">
                                <img src="images/bg/expert/expert-all.png" style={{ width: '100%'}} />
                            </div>
                        </Grid>
                        <Grid item sm={7} className="flex items-center">
                            <div className="w-full flex-1 justify-center">
                                <h2 className="dark">Comptin centralise l'information de vos clients</h2>
                                <div className="sub_h2 dark">
                                    Retrouvez l'intégralité des pièces comptables au même endroit
                                </div>
                                <p className="text-justify">
                                    Votre client dispose d'un accès à Comptin et y dépose ses factures fournisseurs et clients. Il peut synchroniser ses comptes bancaires, annoter les paiements et déposer les relevés bancaires. Vous disposez alors de toutes les informations utiles pour effectuer sa liasse fiscale.
                                </p>
                                <p className="text-justify">
                                    Votre client dispose d'un tableau de bord de suivi d'activité. Il suit ainsi ses dépenses et encaissements au jour le jour. Il peut consulter ses fournisseurs à régler et ses clients à encaisser. Il accède aussi à des informations détaillées sur la répartition des charges pour optimiser son budget.
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div style={{ backgroundColor: '#FFF', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <Grid container spacing={3}>
                        <Grid item sm={5} className="flex items-center">
                            <div className="w-full flex-1 justify-center">
                                <img src="images/bg/expert/expert-control.png" style={{ width: '100%'}} />
                            </div>
                        </Grid>
                        <Grid item sm={7} className="flex items-center">
                            <div className="w-full flex-1 justify-center">
                                <h2 className="dark">Comptin automatise le contrôle de la comptabilité</h2>
                                <div className="sub_h2 dark">
                                    Les écritures comptables sont contrôlées avant le transfert vers votre logiciel métier
                                </div>
                                <p className="text-justify">
                                    Un contrôle de cohérence est effectué avant de récupérer les nouvelles écritures comptables : écriture hors période, journal incorrect, numéro de compte invalide, solde non nul. Une alerte avec la correction est alors proposée.
                                </p>
                                <p className="text-justify">
                                    Vous pouvez ensuite récupérer les écritures pour l'intégrer dans votre logiciel. Vous pouvez exporter au format FEC, Ibiza, Quadratus (avec les fichiers des pièces jointes) et plein d'autres logiciels.
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div style={{ backgroundColor: '#F6F6F6', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                <Grid container spacing={3} direction="row-reverse">
                        <Grid item sm={5} className="flex items-center">
                            <div className="w-full flex-1 justify-center">
                                <img src="images/bg/expert/expert-time.png" style={{ width: '100%'}} />
                            </div>
                        </Grid>
                        <Grid item sm={7} className="flex items-center">
                            <div className="w-full flex-1 justify-center">
                                <h2 className="dark">Comptin vous fait économiser 50% du temps de saisie</h2>
                                <div className="sub_h2 dark">
                                    La saisie est automatisée, vous n'avez plus qu'à vous concentrer sur l'essentiel
                                </div>
                                <p className="text-justify">
                                    Lorsque votre client dépose une facture d'achat par exemple, notre scanner intelligent extrait les données de la pièce comptable (montant TTC, HT, TVA, nom du fournisseur, date de la pièce, numéro de la pièce, type de la pièce, date d'échéance, période de la prestation).
                                </p>
                                <p className="text-justify">
                                    Les données extraites sont analysées et automatiquement attribuées au formulaire de saisie de la pièce comptable. Vous n'avez plus qu'à contrôler et valider. Plus aucune saisie n'est nécessaire.
                                </p>
                                <p className="text-justify">
                                    Les imputations sont pré attribuées automatiquement en fonction des précédentes imputations qui ont été faites sur une pièce similaire. Vous pouvez à tout moment modifier ces imputations si nécessaire.
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div style={{ backgroundColor: '#FFF', paddingTop: '50px', paddingBottom: '50px'}}>
                <Container>
                    <div className="text-center">
                        <h2 className="dark text-center">Vous souhaitez une démonstration de Comptin ?</h2>
                        <div className="sub_h2 dark" style={{ textAlign:'center'}}>
                            Des offres spéciales à prix attractifs pour les cabinets ont été conçues pour vous !
                        </div>
                        <div className="text-center">
                            <Link to={`/contact?service=demo&contact_type=cabinet&version=Expert`}>
                                <Button variant="contained" color="secondary" className="call2action">Demander une démonstration - Experts-comptables <FontAwesomeIcon icon={['fa', 'chevron-right']} size="1x" fixedWidth /></Button>
                            </Link>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default ExpertsComptables;