import { Button, Container, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';


const Cnil = () => {

    const cookies = new Cookies();
    const [showCookiesDialog, setShowCookiesDialog] = useState(null);

    useEffect(() => {
        if (cookies && cookies.get('legalCnil') === 'true') {
            setShowCookiesDialog(false)
        } else {
            setShowCookiesDialog(true)
        }
    },[cookies])

    const handleAcceptCnil = () => {
        cookies.set('legalCnil', true);
        setShowCookiesDialog(false)
    }
    return (
        <>
        {showCookiesDialog ? (

        <div style={{ position:'fixed', bottom:'10px', left:'10px', width:'calc(100% - 20px)', zIndex: 4000}}>
        <Container className="legals-cnil" maxWidth="lg">
        <Paper style={{ padding:'20px', border:'2px solid #3CE1AA', backgroundColor:'#FFF'}} variant="elevation" elevation={5}>
            <Grid container spacing={3}>
                <Grid item sm={10} xs={12} className="flex items-center">
                <div className="w-full flex-1 justify-center">
                    <p className="m-0">
                        Nous utilisons des cookies et partageons certaines de vos informations avec des tiers afin d'analyser le trafic et améliorer l’expérience de nos utilisateurs. En utilisant notre site web, vous acceptez notre politique de cookies. <Link to="/legals/confidentialite">Cliquez-ici pour en savoir +</Link>
                    </p>
                    </div>
                </Grid>
                <Grid item sm={2} xs={12} className="flex items-center">
                    <div className="w-full flex-1 justify-center text-center">
                        <Button variant="contained" color="primary" onClick={() => handleAcceptCnil()}>J'accepte</Button>
                    </div>
                </Grid>
            </Grid>
        </Paper>
        </Container>
        </div>) : ''}</>
    );
};

export default Cnil;