import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TopNavbar from './components/global/TopNavbar';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import Contact from './pages/Contact';
import Pricing from './pages/Pricing';
import ExpertsComptables from './pages/ExpertsComptables';
import Fonctionnalites from './pages/Fonctionnalites';
import Footer from './components/global/Footer';
import Faq from './pages/Faq';
import Cnil from './components/global/Cnil';
import LegalConfidentialite from './pages/Legal/LegalConfidentialite';
import GAnalytics from './components/global/GAnalytics';
import ScrollToTop from './components/utils/ScrollToTop';
import Applications from './pages/Applications';


const theme = createTheme({
  palette: {
       primary: {
         main: '#213754'
       },
       secondary: {
         main: '#3CE1AA'
       }
     }
   });




const App = () => {
  

  return (
    <>
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <GAnalytics />
      <TopNavbar />
      <div id="main-content">
      <Switch>
        <Route path="/fonctionnalites">
            <Fonctionnalites />
          </Route>
          <Route path="/applications">
            <Applications />
          </Route>
          <Route path="/experts-comptables">
            <ExpertsComptables />
          </Route>
          <Route path="/pricing">
            <Pricing />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/legals/confidentialite">
            <LegalConfidentialite />
          </Route>
          
          <Route path="/">
            <Home />
          </Route>

        </Switch>
        
      </div>
      <Footer />
      <Cnil />
      </Router>
    </ThemeProvider>
    </>
  );
}

export default App;
