class AmountHelper {
	

	static display = (value, digits = 2) => {
		if (value === null) {
			return 0;
		}
		if (value === '' || value === undefined) {
			return '';
		}
		return AmountHelper.numberFormat(AmountHelper.clean(value), digits, ',',' ');
	};

	static clean = value => {
		if (value === '' || value === undefined) {
			return 0;
		}
		value = value.toString().replace(',', '.');
		value = value.replace(/\s+/g, '');
		if (Number.isNaN(value)) {
			return '';
		}
		return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
	};

	static numberFormat = (number, decimals, decPoint, thousandsSep) => {
		// eslint-disable-line camelcase
		//  revised by: Jonas Raoni Soares Silva (https://www.jsfromhell.com)
		//  revised by: Luke Smith (https://lucassmith.name)
		//    input by: Kheang Hok Chin (https://www.distantia.ca/)
		//    input by: Jay Klehr
		//    input by: Amir Habibi (https://www.residence-mixte.com/)
		//    input by: Amirouche
		//   example 1: number_format(1234.56)
		//   returns 1: '1,235'
		//   example 2: number_format(1234.56, 2, ',', ' ')
		//   returns 2: '1 234,56'
		//   example 3: number_format(1234.5678, 2, '.', '')
		//   returns 3: '1234.57'
		//   example 4: number_format(67, 2, ',', '.')
		//   returns 4: '67,00'
		//   example 5: number_format(1000)
		//   returns 5: '1,000'
		//   example 6: number_format(67.311, 2)
		//   returns 6: '67.31'
		//   example 7: number_format(1000.55, 1)
		//   returns 7: '1,000.6'
		//   example 8: number_format(67000, 5, ',', '.')
		//   returns 8: '67.000,00000'
		//   example 9: number_format(0.9, 0)
		//   returns 9: '1'
		//  example 10: number_format('1.20', 2)
		//  returns 10: '1.20'
		//  example 11: number_format('1.20', 4)
		//  returns 11: '1.2000'
		//  example 12: number_format('1.2000', 3)
		//  returns 12: '1.200'
		//  example 13: number_format('1 000,50', 2, '.', ' ')
		//  returns 13: '100 050.00'
		//  example 14: number_format(1e-8, 8, '.', '')
		//  returns 14: '0.00000001'
		number = `${number}`.replace(/[^0-9+\-Ee.]/g, '');
		const n = !Number.isFinite(+number) ? 0 : +number;
		const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
		const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
		const dec = typeof decPoint === 'undefined' ? '.' : decPoint;
		let s = '';
		const toFixedFix = function (n, prec) {
			if (`${n}`.indexOf('e') === -1) {
				return +`${Math.round(`${n}e+${prec}`)}e-${prec}`;
			}
			const arr = `${n}`.split('e');
			let sig = '';
			if (+arr[1] + prec > 0) {
				sig = '+';
			}
			return (+`${Math.round(`${+arr[0]}e${sig}${+arr[1] + prec}`)}e-${prec}`).toFixed(prec);
		};
		// @todo: for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec).toString() : `${Math.round(n)}`).split('.');
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		return s.join(dec);
	};
}
export default AmountHelper;
